<template>
  <div>
    <v-col cols="3" v-if="withCampaignData">
      <v-autocomplete v-model="campaignSelected" :items="campaignList" multiple chips item-value="title" item-text="title" label="Campaign Filter" clearable></v-autocomplete>
    </v-col>
    <v-text-field
        v-model="search"
        append-icon="mdi-magnify"
        label="Search"
        single-line
        hide-details
    ></v-text-field>
    <v-data-table
        :headers="endpointHeaders"
        :items="returnData"
        :loading="loading"
        :search="search"
        @click:row="rowClicked"
    >
      <template v-for="(keyName, index) in resultJsonKeys" v-slot:[`item.${keyName}`]="{ item }">
        <v-edit-dialog
            :key="index"
            :return-value.sync="item[keyName]"
            @save="save({[keyName]: item[keyName]}, item.id)"
        >
          <pre>{{ formatLongJson(item[keyName]) }}</pre>
          <template v-slot:input>
            <v-text-field
                v-model="item[keyName]"
                label="Edit"
                single-line
                counter
            ></v-text-field>
          </template>
        </v-edit-dialog>
        <v-btn v-if="keyName === 'id' && tableName === 'Talent'" :key="`viewButton-${item[keyName]}`" color="primary" x-small @click="viewItem(item[keyName])">View/Edit</v-btn>
        <v-btn v-if="keyName === 'id'" :key="`deleteButton-${item[keyName]}`" color="danger" x-small @click="deleteRow(item[keyName])">Delete</v-btn>
      </template>
      <template v-slot:footer.prepend v-if="saving">
        <v-progress-circular indeterminate></v-progress-circular> Saving changes
      </template>
    </v-data-table>
    <TableViewJsonSelectedRowColumnDialog v-if="dialog" :dialog="dialog" :json="json" @TableViewSelectedRowColumnDialogClosed="toggleDialog"></TableViewJsonSelectedRowColumnDialog>
  </div>
</template>

<script>
import axios from "axios";
import TableViewJsonSelectedRowColumnDialog from "@/components/tables/dialogues/TableViewJsonSelectedRowColumnDialog";

export default {
  name: "TableCrudComponent",
  components: {TableViewJsonSelectedRowColumnDialog},
  props: {
    endpoint: {
      type: String, // for example: /api/<endpoint>
      required: true,
    },
    tableName: {
      type: String,
      required: true,
    },
    withCampaignData: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    endpointHeaders() {
      let arr = []

      if (this.loading || this.endpointJson.length < 1)
        return arr

      Object.entries(this.endpointJson[0]).forEach((t) => {
        arr.push({
          text: t[0],
          value: t[0],
        })
      })
      return arr
    },

    resultJsonKeys() {
      let arr = []

      if (this.loading || this.endpointJson.length < 1)
        return arr

      Object.entries(this.endpointJson[0]).forEach((t) => {
        arr.push(t[0])
      })

      return arr
    },

    campaignList() {
      let arr = []

      let keys = Object.keys(this.cookieUtmCampaignGroupBy)

      keys.forEach(t => {
        arr.push({
          title: t
        })
      })

      return arr
    },

    cookieUtmCampaignGroupBy() {
      if (!this.withCampaignData)
        return []
      return this.getTalentDataByCookieCampaignReplacePlus
          .map(t => t.json.cookie.utm_campaign)
          .reduce((r,c) => (r[c] = (r[c] || 0) + 1, r), {})
    },

    getTalentDataByCookieCampaignReplacePlus() {
      if (!this.withCampaignData)
        return []

      return this.getTalentDataByCookieCampaign.map(t => {
        t.json.cookie.utm_campaign = t.json.cookie?.utm_campaign?.replaceAll(/%20/g, "+")
        t.json.cookie.utm_medium = t.json.cookie?.utm_medium?.replaceAll(/%20/g, "+")
        t.json.cookie.utm_source = t.json.cookie?.utm_source?.replaceAll(/%20/g, "+")
        return t
      })
    },

    getTalentDataByCookieCampaign() {
      if (!this.withCampaignData)
        return []

      if (!this.loading) {
        let jsonDataOnly = this.endpointJson.filter(t => t.json !== null)
        // eslint-disable-next-line no-prototype-builtins
        return jsonDataOnly.filter(t => t.json.hasOwnProperty("cookie"))
      }
      else
        return []
    },

    returnData() {
      if (this.campaignSelected.length > 0) {
        return this.getTalentDataByCookieCampaignReplacePlus.filter(t => this.campaignSelected.some(tt => tt === t.json.cookie.utm_campaign))
      }
      else
        return this.endpointJson
    }
  },

  mounted() {
    this.getFromEndpoint()
  },

  data() {
    return {
      endpointJson: [],
      loading: true,
      saving: false,
      dialog: false,
      json: [],
      search: "",
      campaignSelected: [],
    }
  },

  methods: {
    rowClicked(value) {
      this.json = value
      this.dialog = true
    },

    toggleDialog() {
      this.dialog = !this.dialog
    },

    formatLongJson(stringToFormat) {
      if (stringToFormat != null)
        if (JSON.stringify(stringToFormat).length > 200)
          return ">>> Too long, click row to open"
        else
          return stringToFormat
      else
        return "--- No data or empty"
    },

    getFromEndpoint() {
      let self = this

      axios.get(`${this.$store.state.apiUrl}${this.endpoint}`, this.$store.state.headers)
          .then(function (response) {
            self.endpointJson = response.data
            self.loading = false
          });
    },

    save(objectKeyValue, id) {
      this.saving = true
      let self = this

      axios.put(`${this.$store.state.apiUrl}${this.endpoint}/${id}`, objectKeyValue , this.$store.state.headers)
          .then(function (response) {
            self.saving = false
            self.$emit("putSuccess", response.data)
          });
    },

    viewItem(id) {
      this.$router.push(`/talent/${id}`)
    },

    deleteRow(id) {
      let self = this
      let acceptDelete = false

      let confirmPrompt = prompt("Write CONFIRM to confirm delete")

      switch (confirmPrompt) {
        case "CONFIRM":
          acceptDelete = true
          break;
      }

      if (acceptDelete) {
        axios.delete(`${this.$store.state.apiUrl}${this.endpoint}/${id}`, this.$store.state.headers)
            .then(function (response) {
              self.getFromEndpoint()
              self.$emit("deleteSuccess", response.data)
            });
      }
    }
  }
}
</script>

<style scoped>

</style>
